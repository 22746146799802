<template>
  <div>
    <div
      v-if="userRole === 'admin' || userRole === 'master'"
      class="align-center text-center mt-6 mx-6"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{
            $t("tables.createdConditions")
          }}</v-toolbar-title>
        </v-toolbar>
        <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="entries"
            :items-per-page="10"
            fixed-header
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteEntry(item.id)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="headersPt"
            :search="search"
            :items="entries"
            :items-per-page="10"
            fixed-header
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteEntry(item.id)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>

    <div v-if="userRole === 'user'" class="align-center text-center mt-6 mx-6">
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{
            $t("tables.createdConditions")
          }}</v-toolbar-title>
        </v-toolbar>
        <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="userHeaders"
            :search="search"
            :items="entries"
            :items-per-page="10"
            fixed-header
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteEntry(item.id)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="userHeadersPt"
            :search="search"
            :items="entries"
            :items-per-page="10"
            fixed-header
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteEntry(item.id)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>

    <div
      v-if="userRole === 'admin' || userRole === 'master'"
      class="align-center text-center mt-6 mx-6"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{
            $t("tables.createNewCondition")
          }}</v-toolbar-title>
        </v-toolbar>
        <div class="d-flex mt-4">
          <v-flex class="md2 ml-12">
            <v-container fluid>
              <v-select
                class=""
                v-model="device"
                :items="groupDevices"
                :search-input.sync="chosenDevice"
                hide-selected
                :hint="$t('global.maximum1Value')"
                :label="$t('global.device')"
                persistent-hint
                small-chips
                outlined
              >
              </v-select>
            </v-container>
          </v-flex>

          <v-flex class="md2 ml-12">
            <v-container fluid>
              <v-select
                class=""
                v-model="dataType"
                :items="typesOfData"
                :search-input.sync="chosenDataType"
                hide-selected
                :hint="$t('global.maximum1Value')"
                :label="$t('global.dataType')"
                persistent-hint
                small-chips
                outlined
              >
              </v-select>
            </v-container>
          </v-flex>

          <v-flex class="md2 ml-12">
            <v-container fluid>
              <v-select
                class=""
                v-model="condition"
                :items="conditions"
                :search-input.sync="chosenCondition"
                hide-selected
                :hint="$t('global.maximum1Value')"
                :label="$t('global.condition')"
                persistent-hint
                small-chips
                outlined
              >
              </v-select>
            </v-container>
          </v-flex>

          <v-flex class="pt-3 md2 ml-12">
            <v-text-field
              v-model="value"
              :hint="$t('global.example')"
              :label="$t('global.value')"
              autocomplete="off"
              filled
              outlined
              counter="6"
              background-color="white"
            ></v-text-field>
          </v-flex>
          <br />
          <v-btn
            class="ml-12 mt-6 mr-2"
            elevation="2"
            @click="createNewEntry()"
          >
            {{ $t("button.create") }}
          </v-btn>
        </div>
        <br />
        <div class="red--text" v-html="error" />
        <div class="green--text" v-html="success" />
      </v-card>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import AppService from "@/services/AppService";
import HelperMethods from "@/utilities/HelperMethods";
import { mapState } from "vuex";

export default {
  data() {
    return {
      userInfo: {},
      entries: [],
      devices: [],
      groupDevices: [],
      headers: [
        { text: "Device", value: "name" },
        { text: "Data Type", value: "dataType" },
        { text: "Condition", value: "conditionSymbol" },
        { text: "Value", value: "value" },
        { text: "Date", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersPt: [
        { text: "Dispositivo", value: "name" },
        { text: "Grandeza", value: "dataType" },
        { text: "Condição", value: "conditionSymbol" },
        { text: "Valor", value: "value" },
        { text: "Data", value: "createdAt" },
        { text: "Acções", value: "actions", sortable: false },
      ],
      userHeaders: [
        { text: "Device", value: "name" },
        { text: "Data Type", value: "dataType" },
        { text: "Condition", value: "conditionSymbol" },
        { text: "Value", value: "value" },
        { text: "Date", value: "createdAt" },
      ],
      userHeadersPt: [
        { text: "Dispositivo", value: "name" },
        { text: "Grandeza", value: "dataType" },
        { text: "Condição", value: "conditionSymbol" },
        { text: "Valor", value: "value" },
        { text: "Data", value: "createdAt" },
      ],
      typesOfData: [
        "Temperature",
        "Carbon Dioxide",
        "Noise",
        "Humidity",
        "Light Intensity",
        "Air Quality",
        "Pressure",
        "Wifi Signal",
        "Light Temperature",
        "Devices Count",
        "People Flow",
      ],
      typesOfDataPt: [
        "Temperatura",
        "Dióxido de Carbono",
        "Ruído",
        "Humidade",
        "Intensidade da Luz",
        "Qualidade do Ar",
        "Pressão",
        "Sinal Wifi",
        "Temperatura da Luz",
        "Número de Dispositivos",
        "Fluxo de Pessoas",
      ],
      conditions: ["<", ">"],
      model: ["Vuetify"],
      dataType: "",
      condition: "",
      device: "",
      chosenDevice: "",
      chosenDataType: "",
      chosenCondition: "",
      value: null,
      dataTypeId: null,
      deviceId: null,
      groupId: null,
      search: null,
      error: null,
      success: null,
    };
  },
  computed: {
    ...mapState(["userRole"]),
  },
  methods: {
    async retrieveEntries() {
      let self = this;

      try {
        const response = await EventService.getEntries(this.groupId);
      // filter used to remove all entries where the condition value is 0
      // meaning all disconnect/reconnect entries will not be displayed on the analytics menu
        this.entries = response.data.filter(entry => entry.condition !== 0);
      } catch (err) {
        console.log(err);
      }

      this.entries.forEach(function (value) {
        value.createdAt = HelperMethods.formatTimestamp(value["createdAt"]);
        value.name = HelperMethods.nameDevice(value["refDevice"], self.devices);
        value.dataType = HelperMethods.nameDataType(
          value["dataType"],
          self.$i18n.locale,
          self.typesOfData,
          self.typesOfDataPt
        );
        value.conditionSymbol = HelperMethods.convertCondition(
          value["condition"]
        );
      });
    },

    async refreshList() {
      this.retrieveEntries();
    },

    async createNewEntry() {
      const temp = this.value.split("");
      if (temp.length > 6) {
        this.error = "A maximum of 6 digits is allowed for value input.";
        return;
      }
      this.error = null;
      const value = Number(this.value);

      if (isNaN(value)) {
        this.error = "Value can only contain numbers.";
        return;
      }

      for (let i = 0; i < this.groupDevices.length; i++) {
        if (this.device == this.devices[i].name) {
          this.deviceId = this.devices[i].id;
        }
      }

      for (let i = 0; i < this.typesOfData.length; i++) {
        if (this.dataType == this.typesOfData[i]) {
          this.dataTypeId = i;
        }
      }

      let conditionTemp = this.condition;

      if (conditionTemp == "<") {
        conditionTemp = "<".charCodeAt(0);
      } else if (conditionTemp == ">") {
        conditionTemp = ">".charCodeAt(0);
      } else {
        return;
      }

      try {
        const response = await EventService.createNewEntry({
          refGroup: this.groupId,
          refDevice: this.deviceId,
          dataType: this.dataTypeId,
          condition: conditionTemp,
          value: this.value,
        });
        this.error = null;
        this.success = response.data.message;
        await this.refreshList();

        await AppService.addUserAction({
          userId: this.userInfo.id,
          userRole: this.userInfo.refRole,
          message: `add entry id: ${response.data.entry.id} to deviceId:  ${this.deviceId}`,
        });
      } catch (err) {
        this.error = err.response.data.error;
        console.log(err);
      }
    },

    async deleteEntry(id) {
      EventService.delete(id)
        .then(() => {
          this.refreshList();
          AppService.addUserAction({
            userId: this.userInfo.id,
            userRole: this.userInfo.refRole,
            message: `delete entry id: ${id} from deviceId: ${this.deviceId}`,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async mounted() {
    this.userInfo = this.$store.getters.userInfo;
    this.groupId = this.$store.getters.groupId;
    this.devices = this.$store.getters.devices;

    for (let i = 0; i < this.devices.length; i++) {
      this.groupDevices.push(this.devices[i].name);
    }

    this.retrieveEntries();
  },

  watch: {
    //Combobox v-model value is being watched to prevent the selection of more than 1 value
    device(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.device.pop());
      }
    },
    dataType(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.dataType.pop());
      }
    },
    condition(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.condition.pop());
      }
    },
  },
};
</script>

<style scoped></style>
